export enum PinKey {
  PRODUCTS_EDITOR = 'PRODUCTS_EDITOR',
  PRODUCTS_EDITOR_DASHBOARD = 'PRODUCTS_EDITOR_DASHBOARD',
  PRODUCTS_EDITOR_PURCHASING_TAB = 'PRODUCTS_EDITOR_PURCHASING_TAB',
  PRODUCTS_EDITOR_BC_TAB = 'PRODUCTS_EDITOR_BC_TAB',
  PRODUCTS_EDITOR_MARKETING_TAB = 'PRODUCTS_EDITOR_MARKETING_TAB',
  PRODUCTS_EDITOR_PROLINE_TAB = 'PRODUCTS_EDITOR_PROLINE_TAB',
  PRODUCTS_EDITOR_SALES_TAB = 'PRODUCTS_EDITOR_SALES_TAB',
  PRODUCTS_EDITOR_WAREHOUSE_TAB = 'PRODUCTS_EDITOR_WAREHOUSE_TAB',
  PRODUCTS_PRICING = 'PRODUCTS_PRICING',
  PRODUCTS_PRICING_DETAIL_TAB = 'PRODUCTS_PRICING_DETAIL_TAB',
  ACCOUNTING_EXPORT_INVOICES = 'ACCOUNTING_EXPORT_INVOICES',
  TASKS = 'TASKS',
  TASKS_DETAIL = 'TASKS_DETAIL',
  CUSTOMER_TASKS = 'CUSTOMER_TASKS',
  CUSTOMER_TASKS_DETAIL = 'CUSTOMER_TASKS_DETAIL',
  BC_INVENTORY_PUSH = 'BC_INVENTORY_PUSH',
  PICKUP_DATE_TIMES_SETTINGS = 'PICKUP_DATE_TIMES_SETTINGS',
  DELIVERY_QUOTE_MILEAGE_DISCOUNTS = 'DELIVERY_QUOTE_MILEAGE_DISCOUNTS',
  DELIVERY_QUOTE_MONTHS_SETTINGS = 'DELIVERY_QUOTE_MONTHS_SETTINGS',
  DELIVERY_QUOTE_MONTHS_SETTINGS_DETAIL = 'DELIVERY_QUOTE_MONTHS_SETTINGS_DETAIL',
  DELIVERY_QUOTE_PALLET_DISCOUNTS = 'DELIVERY_QUOTE_PALLET_DISCOUNTS',
  IAM_PERMISSIONS = 'IAM_PERMISSIONS',
  IAM_PERMISSIONS_DETAIL = 'IAM_PERMISSIONS_DETAIL',
  IAM_ROLES = 'IAM_ROLES',
  IAM_ROLES_DETAIL = 'IAM_ROLES_DETAIL',
  IAM_ROLES_DETAIL_PERMISSIONS = 'IAM_ROLES_DETAIL_PERMISSIONS',
  IAM_USERS = 'IAM_USERS',
  IAM_USERS_ROLES = 'IAM_USERS_ROLES',
  IAM_USER_PROFILES = 'IAM_USER_PROFILES',
  IAM_USER_PROFILES_DETAIL = 'IAM_USER_PROFILES_DETAIL',
  INVENTORY_WAREHOUSE_INVENTORY = 'INVENTORY_WAREHOUSE_INVENTORY',
  INVENTORY_TAKER = 'INVENTORY_TAKER',
  PRICING_PROJECTION_CATEGORY_MULTIPLIERS = 'PRICING_PROJECTION_CATEGORY_MULTIPLIERS',
  PRICING_PROJECTION_FOREX = 'PRICING_PROJECTION_FOREX',
  PRICING_PROJECTION_INBOUND_FREIGHT = 'PRICING_PROJECTION_INBOUND_FREIGHT',
  PRICING_PROJECTION_INBOUND_TEMPLATE = 'PRICING_PROJECTION_INBOUND_TEMPLATE',
  PRICING_PROJECTION_MANUFACTURERS = 'PRICING_PROJECTION_MANUFACTURERS',
  PRICING_PROJECTION_PRODUCT_MULTIPLIERS = 'PRICING_PROJECTION_PRODUCT_MULTIPLIERS',
  PRICING_PROJECTION_SUPPLIERS = 'PRICING_PROJECTION_SUPPLIERS',
  PRICING_PROJECTION_SUMMARY = 'PRICING_PROJECTION_SUMMARY',
  PRICING_PROJECTION_SUPPLIER_MANUF_DISCOUNT = 'PRICING_PROJECTION_SUPPLIER_MANUF_DISCOUNT',
  PRICING_PROJECTION_VERSIONS = 'PRICING_PROJECTION_VERSIONS',
  COUPON_CODES_USED = 'COUPON_CODES_USED',
  CUSTOM_ASSORTMENTS = 'CUSTOM_ASSORTMENTS',
  CUSTOM_ASSORTMENTS_DETAIL = 'CUSTOM_ASSORTMENTS_DETAIL',
  PRODUCTS_DEALS = 'PRODUCTS_DEALS',
  PRODUCTS_DEALS_DETAIL = 'PRODUCTS_DEALS_DETAIL',
  BANNERS_AND_TILES = 'BANNERS_AND_TILES',
  STAFF_ACCOUNTS = 'STAFF_ACCOUNTS',
  STAFF_ACCOUNT = 'STAFF_ACCOUNT',
  SHIPPING = 'SHIPPING',
  SPIRIT_QUOTE_DATE_RANGE = 'SPIRIT_QUOTE_DATE_RANGE',
  PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES',
  PRODUCT_BRANDS = 'PRODUCT_BRANDS',
  PRODUCT_SITEMAP = 'PRODUCT_SITEMAP',
  ORDERS = 'ORDERS',
}

export interface IRwNavHeader {
  title?: string
  pinKey?: PinKey
  handleClose?: () => void
  handleRefresh?: () => void
  [x: string]: any
}
